import "focus-visible";
import "@/styles/tailwind.css";
import PlausibleProvider from "next-plausible";

export default function App({ Component, pageProps }) {
	return (
		<PlausibleProvider domain="octolense.com">
			<Component {...pageProps} />;
		</PlausibleProvider>
	);
}
